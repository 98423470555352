<template>
	<div v-if="isSuperAdmin">
		<pui-datatable
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:externalFilter="externalFilter"
			:showDeleteBtn="true"
			:actions="actions"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
		></pui-datatable>
	</div>
	<div v-else>
		<pui-datatable
			:modelName="model"
			:modelColumnDefs="columnDefs"
			:externalFilter="externalFilter"
			:showDeleteBtn="true"
			:actions="actions"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import SatelliteTypeActions from './PmSatelliteTypeActions';

//import constants from '@/utils/constants';
import { isSuperAdmin } from '../../api/common';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'pmsatellitetypegrid',
	data() {
		return {
			model: 'pmsatellitetype',
			actions: SatelliteTypeActions.actions,
			masterDetailColumnVisibles: {
				name: true,
				acronym: true,
				color: true,
				description: true,
				disabled: true
			},
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				color: {
					createdCell: (td, cellData, rowData) => {
						return (td.innerHTML = `<label style="font-weight:bold;color:${rowData.color}">${cellData}</label>`);
					}
				}
			},
			isSuperAdmin: false
		};
	},
	computed: {},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		// eslint-disable-next-line no-unused-vars

		if (!this.isSuperAdmin) {
			this.externalFilter = {
				groupOp: 'and',
				rules: [
					{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
					{ field: 'langstatus', op: 'eq', data: 1 }
				],
				groups: []
			};
		}
	},
	created() {},
	methods: {}
};
</script>

<style lang="postcss" scoped></style>
